import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import toast from "react-hot-toast";
import Select from "react-select";

import * as setup from "models/setup";

const AddEditSubTopic = props => {
  const [levelTypes, setLevelTypes] = useState([])
  const [levels, setLevels] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [selectedLevelType, setSelectedLevelType] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedSession, setSelectedSession] = useState(null)

  const sessions = [{
    label: "Sessions",
    options: [
      { label: "Term 1", value: "Term 1" },
      { label: "Term 2", value: "Term 2" },
      { label: "Term 3", value: "Term 3" }
    ]
  }
  ]

  const handleSelectLevelType = (selectedLevelType) => {
    setSelectedLevelType(selectedLevelType)
    //getAllSubCategories(selectedCategory?.label)
  }

  const handleSelectLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel)
  }
  const handleSelectSession = (selectedSession) => {
    setSelectedSession(selectedSession)
  }
  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.fetchData()
    props.setToEdit(false)
    props.setSelected(null)
    window.location.href = '/content-management/quizes-and-tests';
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;

    setIsLoading(true)
    const form = event.currentTarget
    const payload = {
      topic: form.topicName.value,
      sessionName: selectedSession?.value,
      subTopicName: form.subTopicName.value,

    }
    if (props.toEdit) {
      setup.updateSubTopic(payload, token, props.selected?._id)
        .then(resp => {
          const data = resp
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.data)
        })
    } else {
      setup.addSubTopic(payload, token,)
        .then(resp => {
          const data = resp
          setIsLoading(false)
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          console.log(err)
          toast.error(err?.response?.data?.message)
        })

    }
  }
  // useEffect(() => {
  //   setLevelTypes([
  //     {
  //       label: "Level Tpyes",
  //       options: props.levelTypes.map(item => ({
  //         label: item?.levelTypeName,
  //         value: item?.levelTypeName,
  //         levels: item?.levels
  //       }))
  //     }
  //   ])
  // }, [props.levelTypes])
  // useEffect(() => {
  //   console.log(selectedLevelType?.levels)
  //   setLevels([
  //     {
  //       label: "Level ",
  //       options: selectedLevelType?.levels.map(item => ({
  //         label: item?.levelName,
  //         value: item?.levelName,
  //       }))
  //     }
  //   ])
  // }, [selectedLevelType])
  return (
    <React.Fragment>
      <Modal show={props.showModal}
        centered
        onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} Sub Strand
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="subTopicName">
              <Form.Label>
                Sub Strand Name
              </Form.Label>
              <Form.Control
                defaultValue={props.selected?.subTopicName}
                placeholder="Enter Sub Strand"

              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="topicName">
              <Form.Label>
                Strand Name
              </Form.Label>
              <Form.Control
                value={props?.topic}
                placeholder="" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Sessions
              </Form.Label>
              <Select
                value={selectedSession}
                onChange={setSelectedSession}
                options={sessions}
              />
            </Form.Group>

            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

AddEditSubTopic.propTypes = {}

export default AddEditSubTopic