import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TableTemplate from "components/TableTemplate"

const Lectures = props => {
  const [lectures, setLectures] = useState()
  return (
    <div className="page-content">
      <TableTemplate />
    </div>
  )
}

Lectures.propTypes = {}

export default Lectures