import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import Select from "react-select";

import toast from "react-hot-toast";

import * as file from "models/files";
import * as setup from "models/setup";

const AddEditContent = props => {
  const [isLoading, setIsLoading] = useState(false)

  const [topics, setTopics] = useState([])
  const [preTopics, setPreTopics] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [counties, setCounties] = useState([])
  const [levelTypes, setLevelTypes] = useState([])
  const [levels, setLevels] = useState([])
  const [selectedFileType, setSelectedFileType] = useState({ name: "pdf", value: "pdf" })
  const [selectedLevelType, setSelectedLevelType] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedSession, setSelectedSession] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)

  const sessions = [{
    label: "Sessions",
    options: [
      { label: "Term 1", value: "Term 1" },
      { label: "Term 2", value: "Term 2" },
      { label: "Term 3", value: "Term 3" }
    ]
  }
  ]

  const handleSelectSession = (selectedSession) => {
    setSelectedSession(selectedSession)
  }

  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.fetchData()
    props.setToEdit(false)
    setSelectedFileType({ name: "pdf", value: "pdf" })
    setSelectedFile(null)
    props.setSelected(null)
  }


  const handleSelectLevelType = (selectedLevelType) => {
    setSelectedLevelType(selectedLevelType)
    //getAllSubCategories(selectedCategory?.label)
  }

  const handleSelectLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel)
  }
  const handleSelectTopic = (selectedTopic) => {
    setSelectedTopic(selectedTopic)

  }
  console.log(props?.subTopicId)
  const handleFileChange = e => {
    const file = e.target.files[0]
    console.log(file)
    setSelectedFile(file)
  }

  const uploadFile = (form, token) => {
    setIsLoading(true)
    // const payload = {
    //   // id: uuid.v4(),
    //   topicName: form.topicName.value,
    //   sessionName: form.sessionName.value,
    //   level: selectedLevel.value,
    //   levelType: selectedLevelType.value
    // }
    const formData = new FormData()

    //formData.append('subtopic', selectedTopic.value);
    // formData.append('contentName', form.contentName.value);
    // formData.append('level', selectedLevel.value);
    formData.append('sessionName', selectedSession.value);
    formData.append('fileType', selectedFileType?.value);
    // formData.append('levelType', selectedLevelType.value);

    formData.append("file", selectedFile)
    setup.uploadContent(formData, props?.subTopicId, token)
      .then(resp => {
        setIsLoading(false)
        closeModal()
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsLoading(true)

    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;

    const form = event.currentTarget
    uploadFile(form, token)

  }

  useEffect(() => {
    props.selected?.fileType === "pdf" ? setSelectedFileType({
      label: "Pdf",
      value: "pdf",
    }) : props.selected?.fileType === "audio" ? setSelectedFileType({
      label: "Audio",
      value: "audio",
    }) : props.selected?.fileType === "video  " ? setSelectedFileType({
      label: "Video",
      value: "video",
    }) : null
  }, [props.selected])


  // useEffect(() => {
  //   setLevelTypes([
  //     {
  //       label: "Level Tpyes",
  //       options: props.levelTypes.map(item => ({
  //         label: item?.levelTypeName,
  //         value: item?.levelTypeName,
  //         levels: item?.levels
  //       }))
  //     }
  //   ])
  // }, [props.levelTypes])

  // useEffect(() => {
  //   setLevels([
  //     {
  //       label: "Level ",
  //       options: selectedLevelType?.levels.map(item => ({
  //         label: item?.levelName,
  //         value: item?.levelName,
  //       }))
  //     }
  //   ])
  // }, [selectedLevelType])

  // useEffect(() => {

  // }, [selectedLevel, selectedLevelType])
  // useEffect(() => {
  //   getTopics()
  // }, [selectedLevel])
  return (
    <React.Fragment>
      <Modal show={props.showModal} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} Content
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
          >
            {/* <Form.Group className="mb-3" controlId="category">
              <Form.Label>
                Level Type
              </Form.Label>
              <Select
                value={selectedLevelType}
                onChange={handleSelectLevelType}
                options={levelTypes}
              />
            </Form.Group>
            */}
            {/* <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Level
              </Form.Label>
              <Select
                value={selectedLevel}
                onChange={handleSelectLevel}
                options={levels}
              />
            </Form.Group> */}
            {/* <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Topic Name
              </Form.Label>
              <Select
                value={selectedTopic}
                onChange={handleSelectTopic}
                options={topics}
              />
            </Form.Group> */}
            {/* <Form.Group className="mb-3" controlId="contentName">
              <Form.Label>
                Content Name
              </Form.Label>
              <Form.Control
                placeholder="Enter Content Name"
                defaultValue={props.selected?.contentName}
                required
              />
            </Form.Group> */}

            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Sessions
              </Form.Label>
              <Select
                value={selectedSession}
                onChange={setSelectedSession}
                options={sessions}
              />
            </Form.Group>
            {props.toEdit === false && (
              <Form.Group className="mb-3">
                <Form.Label>
                  Select File Type
                </Form.Label>
                <div className="d-flex" >
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value }),
                          console.log(selectedFile)
                      }}
                      type="radio"
                      defaultChecked
                      name="flexRadioDisabled"
                      value={"pdf"}
                      id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Pdf
                    </label>
                  </div>
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value }),
                          console.log(selectedFile)
                      }}
                      value={"audio"}
                      type="radio"
                      name="flexRadioDisabled"
                      id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Audio
                    </label>
                  </div>
                  <div className="form-check me-2">
                    <input className="form-check-input"
                      onChange={(e) => {
                        setSelectedFileType({ name: e.target.value, value: e.target.value }),
                          console.log(selectedFile)
                      }}
                      type="radio"
                      value={"video"}
                      name="flexRadioDisabled" id="flexRadioDisabled" />
                    <label className="form-check-label" >
                      Video
                    </label>
                  </div>
                </div>
              </Form.Group>
            )}
            {props.toEdit === false && (
              <Form.Group className="mb-3" controlId="">
                <Form.Label>
                  Upload File
                </Form.Label>
                <div className="d-flex align-items-center">
                  <input
                    name="file"
                    id="file"
                    className="form-control"
                    type="file"
                    accept={selectedFileType.value === "audio" ? '.mp3' : selectedFileType.value === "pdf" ? '.pdf' : selectedFileType.value === "video" ? '.mp4' : null}

                    onChange={handleFileChange}
                    required
                  />
                </div>
              </Form.Group>
            )}

            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}

              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

AddEditContent.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  toEdit: PropTypes.bool,
  setToEdit: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  fetchData: PropTypes.func
}


export default AddEditContent