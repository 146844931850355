import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Container } from "react-bootstrap"
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import moment from "moment"
import TableTemplate from "components/TableTemplate"

import * as setup from "models/setup";

const UnitContent = props => {
  const [units, setUnits] = useState([])

  //processing variables
  const [isLoaded, setIsLoaded] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  //modal
  const [showAddEditUnit, setShowAddEditUnit] = useState(false)
  const [showDeleteLevelUnit, setShowDeleteUnit] = useState(false)

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <Link
              className="btn btn-sm text-info text-nowrap "
              to={`/content-management/contents/${row?.unitName}`}>
              View
            </Link>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
    { name: "Name", selector: row => row?.unitName },

    // {
    //   name: "Status",
    //   selector: row =>
    //     row?.levelTypeStatus === "active" ? "Active" :
    //       row?.levelTypeStatus === "inactive" ? "Inactive" :
    //         row?.levelTypeStatus === "suspended" ? "Suspended" : null,

    //   conditionalCellStyles: [
    //     {
    //       when: (row) => row?.levelTypeStatus === 'active',
    //       style: {
    //         color: 'green',
    //       },
    //     },
    //     {
    //       when: (row) => row?.levelTypeStatus === 'inactive',
    //       style: {
    //         color: 'orange',
    //       },
    //     },
    //     {
    //       when: (row) => row?.levelTypeStatus === 'suspended',
    //       style: {
    //         color: 'danger',
    //       },
    //     },
    //   ]
    // },
    { name: "Updated", selector: row => moment(row.updatedAt).format('LL'), },
    { name: "Created", selector: row => moment(row.createdAt).format('LL'), }


  ]

  const subHeaderNav = (
    <div className="d-flex">

    </div>
  )

  const getAllUnits = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setup.getAllUnits(params, token)
      .then(resp => {
        const data = resp.units
        setUnits(data)
        setIsLoaded(true)
      }).catch(err => {
        console.log(err)
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    getAllUnits()
  }, [])
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <Card.Header >
            <Card.Title className="text-primary">Contents | Learnnig Areas</Card.Title>
          </Card.Header>
          <Card.Body>
            <TableTemplate
              columns={columns}
              rows={units}
              dataLoaded={isLoaded}
              subHeaderNav={subHeaderNav}
            />


          </Card.Body>
        </Card>
      </Container>

    </div>
  )
}

UnitContent.propTypes = {}

export default UnitContent