import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, Card, Col, Container, Row } from "react-bootstrap"
import moment from "moment"
import { Link } from "react-router-dom";
// Toast Alert
import toast from "react-hot-toast"
import * as setup from "models/setup";

import TableTemplate from "components/TableTemplate"

import AddEditTopic from "./AddEditTopic"

const Topics = props => {
  const [topics, setTopics] = useState([])
  const [levelTypes, setLevelTypes] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  const [selectedLevel, setSelectedLevel] = useState('')
  const [unitName, setUnitName] = useState('')

  const [showAddEditTopic, setShowAddEditTopic] = useState(false)
  const [showDeleteTopic, setShowDeleteTopic] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)


  const {
    match: { params },
  } = props

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <Link
              className="btn btn-sm text-info text-nowrap "
              to={`/setup/learning-area/${params?.unitName}/${row?.topicName}`}>
              View
            </Link>
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddEditTopic(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteTopic(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
    { name: "Topic Name", selector: row => row?.topicName },

    { name: "LeveL Type", selector: row => row?.levelType },
    { name: "Level", selector: row => row?.level },
    { name: "Created", selector: row => moment(row.created).format('LL'), }


  ]
  const subHeaderNav = (
    <div className="d-flex w-100 justify-content-between">
      <div className="d-flex align-items-center">
        <h5 className="text-primary"> Strands</h5>
      </div>
      <button className="btn btn-primary"
        onClick={() => setShowAddEditTopic(true)}>
        Add Strand
      </button>
    </div>
  )
  const getTopicByLevel = () => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setIsLoaded(false)
    setup.getAllTopics(`unitId=${params?.id}`, token)
      .then(resp => {
        const data = resp.topics
        console.log(data)
        setTopics(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }
  const getUnitById = () => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setup.getUnitById(params?.id, token)
      .then(resp => {
        const data = resp?.unit
        setUnitName(data?.unitName)
        //setTopics(data?.topics)
        setIsLoaded(true)
      }).catch(err => {
        console.log(err)
        toast.error(err?.response?.data?.message)
      })
  }
  const getAllLevelTypes = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setIsLoaded(false)
    setup.getAllLevelTypes(params, token)
      .then(resp => {
        const data = resp.levelTypes
        setLevelTypes(data)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  const getTopics = () => {
    setup.getAllTopics(`unit=${params?.unitName}`)
      .then(resp => {
        const data = resp.topics
        setTopics(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    getTopics()
    getAllLevelTypes()
  }, [])

  useEffect(() => {
  }, [selectedLevel])
  return (
    <div className="page-content ">
      <Container fluid className=" p-2">
        <Row>
          {/* <Col md={3}>
            <Card>
              <Card.Header className="">
                <Card.Title className="text-primary">LevelTypes</Card.Title>
              </Card.Header>

              <Card.Body>
                {levelTypes.map((item, index) => (
                  <div key={index}>
                    <h6>{item?.levelTypeName}</h6>
                    <div>
                      {item.levels.map((title, key) => (


                        <li
                          key={key}
                          className=" d-flex"
                        >
                          <a className={`d-flex m-0 p-0 align-items-center justify-content-start w-50 me-md-3 px-2 rounded
                  
                  bg-${selectedLevel === title?.levelName ? "primary" : "white"} 
                  text-${selectedLevel === title.levelName ? "white" : "primary"}
                  `}

                            onClick={() => setSelectedLevel(title?.levelName)}> {title.levelName}</a>

                        </li>

                        // <li key={key}>
                        //   {title?.levelName}
                        // </li>
                      ))}
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </Col> */}
          <Col className="">
            <Card>
              <Card.Header >
                <Card.Title className="text-primary"><Link
                  to={`/setup/learning-areas`}>
                  Learning Areas
                </Link> | {params?.unitName}</Card.Title>
              </Card.Header>
              <Card.Body>
                <TableTemplate
                  subHeaderNav={subHeaderNav}
                  dataLoaded={isLoaded}
                  columns={columns}
                  rows={topics}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <AddEditTopic
          showModal={showAddEditTopic}
          setShowModal={setShowAddEditTopic}
          toEdit={toEdit}
          setToEdit={setToEdit}
          selected={selected}
          setSelected={setSelected}
          levelTypes={levelTypes}
          unit={params?.unitName}
          fetchData={getTopics}
        />
      </Container>
    </div >
  )
}

Topics.propTypes = {}

export default Topics