import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const PackageAndSubscription = props => {
  const getPackages = () => {

  }

  useEffect(() => {
    getPackages()
  }, [])

  return (
    <div>index</div>
  )
}

PackageAndSubscription.propTypes = {}

export default PackageAndSubscription