import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Col, Container, Row } from "react-bootstrap"
import moment from "moment"
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import TableTemplate from "components/TableTemplate"

import * as setup from "models/setup";

const SubTopicContent = props => {
  const [subTopics, setSubTopics] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)
  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  const [showAddEditSubTopic, setShowAddEditSubTopic] = useState(false)
  const [showDeleteSubTopic, setShowDeleteSubTopic] = useState(false)

  const {
    match: { params },
  } = props

  const columns = [
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <Link
              className="btn btn-sm text-info text-nowrap "
              to={`/content-management/contents/${params?.unitName}/${params?.topicName}/${row?._id}`}>
              View
            </Link>
          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
    { name: "Topic Name", selector: row => row?.subTopicName },
    { name: "Created", selector: row => moment(row.created).format('LL'), }


  ]
  const subHeaderNav = (
    <div className="d-flex w-100 justify-content-between">
      <div className="d-flex align-items-center">
        <h5 className="text-primary">Sub Strands</h5>
      </div>
    </div>
  )
  const getAllSubTopics = () => {
    setup.getAllSubTopics(`topic=${params?.topicName}`)
      .then(resp => {
        const data = resp.subTopics
        setSubTopics(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    getAllSubTopics()
  }, [])
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header >
                <Card.Title className="text-primary"><Link
                  to={`/content-management/contents/learning-areas`}>
                  Learning Areas
                </Link> | <Link
                  to={`/content-management/contents/${params?.unitName}`}>
                    {params?.unitName}
                  </Link> | {params?.topicName}</Card.Title>
              </Card.Header>
              <Card.Body>
                <TableTemplate
                  dataLoaded={isLoaded}
                  subHeaderNav={subHeaderNav}
                  columns={columns}
                  rows={subTopics}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

SubTopicContent.propTypes = {}

export default SubTopicContent