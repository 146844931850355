import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom";
import TableTemplate from "components/TableTemplate"
import ExamInterface from "./ViewExam"


const ExamsAndTests = props => {
  const [contents, setContents] = useState()
  const [isLoaded, setIsLoaded] = useState(false)
  const [showAddQuizModal, setShowAddQuizModal] = useState(false)
  const [activeTab, setActiveTab] = useState({ name: "Quizes", value: 'quiz' },)

  const {
    match: { params },
  } = props

  const tabs = [
    { name: "Quizes", value: 'quiz' },
    { name: "Tests", value: 'test' }
  ]
  const columns = [
    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <Link
              className="btn btn-sm text-info text-nowrap "
              to={`/content-management/quizes-and-tests/view/${activeTab?.value}/${row?._id}`}>
              View
            </Link>
          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
    { name: "Topic Name", selector: row => row?.subTopicName },
    { name: "Created", selector: row => moment(row.created).format('LL'), }


  ]

  const subHeaderNav = (
    <div className=" d-flex w-100 justify-content-between">
      <div className="d-flex">
        {tabs.map((item, index) =>
          <div key={index} className={`btn me-3 btn-${activeTab?.name === item?.name ? "primary" : "light"}`}
            onClick={() => { setActiveTab(item) }}>
            {item?.name}
          </div>
        )}
      </div>

      <Link
        className="btn btn-md btn-primary  "
        to={`/content-management/quizes-and-tests/${activeTab?.value}`}>
        Create Quiz/Test
      </Link>
    </div>
  )

  const getQuizes = () => {

  }

  const getTests = () => {

  }

  useEffect(() => {
    if (activeTab?.name === "Quizes") {
      getQuizes()
    } else if (setActiveTab?.name === "Tests") {
      getTests()
    } else { null }
  }, [activeTab])
  return (
    <div className="page-content">
      <Card>
        <Card.Body>
          <TableTemplate
            columns={columns}
            rows={contents}
            dataLoaded={isLoaded}
            subHeaderNav={subHeaderNav}
          />
        </Card.Body>
      </Card>
    </div>
  )
}

ExamsAndTests.propTypes = {}

export default ExamsAndTests