import React from 'react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

const DeleteUnit = props => {

  const deleteunit = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success"
        });
      }
    })
  }

  return (
    <div>

    </div>
  )
}

DeleteUnit.propTypes = {}

export default DeleteUnit