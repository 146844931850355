import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner, InputGroup } from "react-bootstrap"
import toast from "react-hot-toast";
import Select from "react-select";

import * as auth from "models/auth";
import * as setup from "models/setup";

const AddEditUser = props => {
  const [isLoading, setIsLoading] = useState(false)

  const [levelTypes, setLevelTypes] = useState([])
  const [levels, setLevels] = useState([])
  const [selectedUserType, setSelecteduserType] = useState(null)
  const [selectedLevelType, setSelectedLevelType] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedGender, setSelectedGender] = useState(null)
  const userTypes = [
    {
      label: "User Types", options: [
        {
          label: "Super Admin",
          value: "super-admin",
        },
        {
          label: "Admin",
          value: "admin",
        },
        {
          label: "Content Creator ",
          value: "content-creator",
        },
        {
          label: "Content Evaluator ",
          value: "content-evaluator",
        },
        {
          label: "Marketer",
          value: "marketer",
        }, {
          label: "Sales Person",
          value: "sales-person",
        }, {
          label: "Publisher",
          value: "publisher",
        }, {
          label: "Examiner",
          value: "examiner",
        },
        {
          label: "Instructor",
          value: "instructor",
        },
        {
          label: "Student",
          value: "student",
        },


      ]
    }
  ]

  const gender = [{
    label: "Gender",
    options: [{ label: "Male", value: "male" }, { label: "Female", value: "female" }]
  }]

  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.fetchData()
    props.setToEdit(false)
    props.setSelected(null)
    setSelecteduserType(null)
  }

  const handleSelectGender = (selectedGender) => {
    setSelectedGender(selectedGender)
  }

  const handleSelectedUserType = (selectedUserType) => {
    setSelecteduserType(selectedUserType)
  }
  const handleSelectLevelType = (selectedLevelType) => {
    setSelectedLevelType(selectedLevelType)
    //getAllSubCategories(selectedCategory?.label)
  }

  const handleSelectLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;

    setIsLoading(true)
    const form = event.currentTarget
    const payload = {
      fullName: form.fullName.value,
      userEmail: form.userEmail.value,
      phoneNumber: form.phoneNumber.value,
      location: form.location.value,
      userType: selectedUserType?.value,
      ...(selectedUserType?.value === 'student'
        ? { levelType: selectedLevelType.value }
        : {}),
      ...(selectedUserType?.value === 'student'
        ? { level: selectedLevel.value }
        : {})
    }
    if (props.toEdit) {
      auth.updateProfile(props.selected?._id, payload, token)
        .then(resp => {
          const data = resp
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.data)
        })
    } else {
      auth.registerAsAdmin(payload, token)
        .then(resp => {
          const data = resp
          setIsLoading(false)
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          console.log(err)
          toast.error(err?.response?.data?.message)
        })

    }
  }
  const getAllLevelTypes = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setup.getAllLevelTypes(params, token)
      .then(resp => {
        const data = resp.levelTypes
        setLevelTypes([
          {
            label: "Level Tpyes",
            options: data.map(item => ({
              label: item?.levelTypeName,
              value: item?.levelTypeName,
              levels: item?.levels
            }))
          }
        ])
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    getAllLevelTypes()
  }, [])

  useEffect(() => {
    setLevels([
      {
        label: "Level ",
        options: selectedLevelType?.levels.map(item => ({
          label: item?.levelName,
          value: item?.levelName,
        }))
      }
    ])
  }, [selectedLevelType])

  useEffect(() => {
    setLevels([
      {
        label: "Level ",
        options: selectedLevelType?.levels.map(item => ({
          label: item?.levelName,
          value: item?.levelName,
        }))
      }
    ])
  }, [props.selected])
  return (
    <React.Fragment>
      <Modal show={props.showModal} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} User
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>
                User Type
              </Form.Label>
              <Select
                value={selectedUserType}
                onChange={handleSelectedUserType}
                options={userTypes}
              />
            </Form.Group>

            {selectedUserType && (
              <div>
                <Form.Group className="mb-3" controlId="fullName">
                  <Form.Label>
                    Full Name
                  </Form.Label>
                  <Form.Control
                    placeholder="e.g John Doe"
                    defaultValue={props.selected?.fullName}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="userEmail">
                  <Form.Label>
                    User Email
                  </Form.Label>
                  <Form.Control
                    placeholder="e.g johndoe@gmail.com"
                    required
                    defaultValue={props.selected?.userEmail}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="phoneNumber">
                  <Form.Label>
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    placeholder="e.g 0712345678"
                    required
                    defaultValue={props.selected?.phoneNumber}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="level">
                  <Form.Label>
                    Gender
                  </Form.Label>
                  <Select
                    value={selectedGender}
                    onChange={handleSelectGender}
                    options={gender}
                  />
                </Form.Group>
                {selectedUserType?.value === "student" && (<Form.Group className="mb-3" controlId="levelType">
                  <Form.Label>
                    Level Type
                  </Form.Label>
                  <Select
                    value={selectedLevelType}
                    onChange={handleSelectLevelType}
                    options={levelTypes}
                  />
                </Form.Group>)}
                {selectedLevelType?.value === undefined ? null : (<Form.Group className="mb-3" controlId="level">
                  <Form.Label>
                    Level
                  </Form.Label>
                  <Select
                    value={selectedLevel}
                    onChange={handleSelectLevel}
                    options={levels}
                  />
                </Form.Group>)}

                <Form.Group className="mb-3" controlId="location">
                  <Form.Label>
                    Location
                  </Form.Label>
                  <Form.Control
                    placeholder="e.g Kisumu"
                    required
                    defaultValue={props.selected?.location}

                  />
                </Form.Group>
                <div className="mt-4 d-grid mb-5 px-5">
                  <button
                    className="btn btn-primary btn-block btn-lg rounded-0"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Processing...
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            )}




          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

AddEditUser.propTypes = {}

export default AddEditUser