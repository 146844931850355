import React from 'react'
import PropTypes from 'prop-types'
import feature from "./../../components/kenyan-counties.geojson"

const Analytics = props => {
  return (
    <div className="page-content">

    </div>
  )
}

Analytics.propTypes = {}

export default Analytics