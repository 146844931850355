import React from 'react'
import PropTypes from 'prop-types'

const Logs = props => {
  return (
    <div className="page-content">

    </div>
  )
}

Logs.propTypes = {}

export default Logs