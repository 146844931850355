import React, { useState } from 'react';
import PropTypes from 'prop-types'
import './ExamInterface.css';

const ExamInterface = props => {
  const [answers, setAnswers] = useState({});

  const questions = [
    { id: 1, type: 'mcq', question: 'What is the capital of Kenya?', options: ['Nairobi', 'Mombasa', 'Kisumu', 'Eldoret'] },
    { id: 2, type: 'truefalse', question: 'The Earth is the third planet from the Sun.' },
    { id: 3, type: 'shortanswer', question: 'Define the term "photosynthesis."' },
    { id: 4, type: 'essay', question: 'Discuss the impact of climate change on agriculture.' },
    { id: 6, type: 'matching', question: 'Match the terms with their definitions', options: [{ term: 'Photosynthesis', definition: 'Plants making food' }, { term: 'Evaporation', definition: 'Water turning to vapor' }] },
    { id: 7, type: 'checkbox', question: 'Select all the elements that are gases at room temperature.', options: ['Oxygen', 'Iron', 'Carbon dioxide', 'Sodium', 'Nitrogen'] }
  ];
  const {
    match: { params },
  } = props

  const getQuestion = () => {

  }

  const handleChange = (questionId, value, isCheckbox = false) => {
    if (isCheckbox) {
      const currentAnswers = answers[questionId] || [];
      const updatedAnswers = currentAnswers.includes(value)
        ? currentAnswers.filter((answer) => answer !== value)
        : [...currentAnswers, value];
      setAnswers({ ...answers, [questionId]: updatedAnswers });
    } else {
      setAnswers({ ...answers, [questionId]: value });
    }
  };

  const renderQuestion = (question) => {
    switch (question.type) {
      case 'mcq':
        return (
          <div className="question">
            <p>{question.question}</p>
            {question.options.map((option, index) => (
              <label key={index} className="option">
                <input
                  type="radio"
                  name={`mcq-${question.id}`}
                  value={option}
                  checked={answers[question.id] === option}
                  onChange={() => handleChange(question.id, option)}
                />
                {option}
              </label>
            ))}
          </div>
        );
      case 'truefalse':
        return (
          <div className="question">
            <p>{question.question}</p>
            <label className="option">
              <input
                type="radio"
                name={`truefalse-${question.id}`}
                value="true"
                checked={answers[question.id] === 'true'}
                onChange={() => handleChange(question.id, 'true')}
              />
              True
            </label>
            <label className="option">
              <input
                type="radio"
                name={`truefalse-${question.id}`}
                value="false"
                checked={answers[question.id] === 'false'}
                onChange={() => handleChange(question.id, 'false')}
              />
              False
            </label>
          </div>
        );
      case 'shortanswer':
        return (
          <div className="question">
            <p>{question.question}</p>
            <input
              type="text"
              value={answers[question.id] || ''}
              onChange={(e) => handleChange(question.id, e.target.value)}
              className="text-input"
            />
          </div>
        );
      case 'essay':
        return (
          <div className="question">
            <p>{question.question}</p>
            <textarea
              value={answers[question.id] || ''}
              onChange={(e) => handleChange(question.id, e.target.value)}
              className="textarea"
            />
          </div>
        );
      case 'fillintheblank':
        return (
          <div className="question">
            <p>{question.question}</p>
            <input
              type="text"
              value={answers[question.id] || ''}
              onChange={(e) => handleChange(question.id, e.target.value)}
              className="text-input"
            />
          </div>
        );
      case 'matching':
        return (
          <div className="question">
            <p>{question.question}</p>
            {question.options.map((option, index) => (
              <div key={index}>
                <span className="match-term">{option.term}</span>
                <input
                  type="text"
                  placeholder="Answer"
                  onChange={(e) => handleChange(`${question.id}-${index}`, e.target.value)}
                  className="text-input"
                />
              </div>
            ))}
          </div>
        );
      case 'checkbox':
        return (
          <div className="question">
            <p>{question.question}</p>
            {question.options.map((option, index) => (
              <label key={index} className="option">
                <input
                  type="checkbox"
                  value={option}
                  checked={(answers[question.id] || []).includes(option)}
                  onChange={() => handleChange(question.id, option, true)}
                />
                {option}
              </label>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="exam-container">
      <h2>Quiz </h2>
      <input className="form-check-input"
        type="radio"
        value={"video"}
        name="flexRadioDisabled" id="flexRadioDisabled" />
      {questions.map((question) => (
        <div key={question.id} className="question-container">
          {renderQuestion(question)}
        </div>
      ))}
      <button className="submit-btn" onClick={() => console.log(answers)}>
        Submit
      </button>
    </div>
  );
};
ExamInterface.propTypes = {}
export default ExamInterface;
